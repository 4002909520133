class Tooltip {
  constructor() {
    this.initializeTippy();
    document.addEventListener(
      'swiper-clients-autos-updated',
      this.shipping_init,
    );
    document.addEventListener('compare-add', this.data_tippy_content_init);
    document.addEventListener('compare-remove', this.data_tippy_content_init);
    document.addEventListener('calculated', this.data_tippy_content_hover);
  }

  async initializeTippy() {
    const tippyElements1 = document.querySelectorAll('[data-tippy-content]');
    const tippyElements2 = document.querySelectorAll(
      '[data-tippy-content-hover]',
    );
    const tippyElements3 = document.querySelectorAll('[data-tippy-shipping]');

    if (
      tippyElements1.length + tippyElements2.length + tippyElements3.length <=
      0
    ) {
      return false;
    }

    if (!document.tippy) {
      const module = await import('tippy.js');
      document.tippy = module.default;
    }

    this.shipping_init();
    this.data_tippy_content_init();
    this.data_tippy_content_hover();
  }

  data_tippy_content_init() {
    document.tippy('[data-tippy-content]', {
      trigger: 'manual',
      allowHTML: true,
      arrow: false,
      delay: [0, 1000],
      zIndex: 10,
    });
  }

  async data_tippy_content_hover() {
    const elements = document.querySelectorAll('[data-tippy-content-hover]');
    if (elements.length == 0) {
      return;
    }

    if (!document.tippy) {
      const module = await import('tippy.js');
      document.tippy = module.default;
    }

    elements.forEach((el) => {
      document.tippy(el, {
        allowHTML: true,
        arrow: false,
        content: el.dataset.tippyContentHover,
        zIndex: 10,
      });
    });
  }

  shipping_init() {
    document.querySelectorAll('[data-tippy-shipping]')?.forEach((el) => {
      document.tippy(el, {
        trigger: 'click',
        allowHTML: true,
        content: el.nextElementSibling.innerHTML,
        zIndex: 10,
        maxWidth: 'none',
        appendTo: 'parent',
        arrow: false,
        getReferenceClientRect: () => ({
          left: 0,
          right: 0,
        }),
      });
    });
  }
}
export default Tooltip;
