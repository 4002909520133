class FloatSidebar {
  constructor(sidebarElement, contentElement) {
    this.sidebar = sidebarElement?.classList.contains('not-float')
      ? null
      : sidebarElement;
    this.relative = contentElement;
    this.module = null;
    this.topSpacing = 16 + (this.header?.clientHeight ?? 0);
    this.bottomSpacing = 16;

    window.addEventListener('resize', () => {
      this.setScrollSidebar();
    });
    window.addEventListener('facetwp-loaded', () => {
      this.setScrollSidebar();
    });
    // window.addEventListener("facetwp-refresh", () => {
    //     this.setScrollSidebar();
    // });
    this.setScrollSidebar();
  }

  async setScrollSidebar() {
    if (window.innerWidth >= 1024 && this.sidebar) {
      if (!this.module) {
        // Якщо неініціалізовано сайдбар
        const module = await import('float-sidebar/src/float-sidebar');
        this.module = module.default({
          sidebar: this.sidebar,
          relative: this.relative,
          topSpacing: this.topSpacing,
          bottomSpacing: this.bottomSpacing,
        });
      } else {
        // Якщо ініціалізовано сайдбар
        // if (this.sidebar.nextElementSibling.clientHeight < this.sidebar.clientHeight) {
        //     this.module.destroy();
        //     this.module = null;
        // } else {
        // }
        this.module.forceUpdate();
      }
    } else {
      // Якщо мобілка/планшет
      if (this.module) {
        this.module.destroy();
        this.module = null;
      }
    }
  }
}

export default FloatSidebar;
