class SlideShow {
  constructor(selector, interval = 3000) {
    this.slideShows = document.querySelectorAll(selector);
    this.interval = interval;

    this.slideShows.forEach((slideShow) => {
      let currentImageIndex = 0;
      const slides = slideShow.children;

      if (slides.length) {
        slides[currentImageIndex].classList.remove('opacity-0');
        slides[currentImageIndex].classList.add('opacity-100');

        setInterval(() => {
          slides[currentImageIndex].classList.remove('opacity-100');
          slides[currentImageIndex].classList.add('opacity-0');

          currentImageIndex++;
          if (currentImageIndex >= slides.length) {
            currentImageIndex = 0;
          }

          slides[currentImageIndex].classList.remove('opacity-0');
          slides[currentImageIndex].classList.add('opacity-100');
        }, this.interval);
      }
    });
  }
}

export default SlideShow;
