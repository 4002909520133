class ScrollSmooth {
  constructor(options) {
    this.anchors = document.querySelectorAll('a[href^="#"]');
    this.sections = document.querySelectorAll('section');
    this.headerHeight = options.headerHeight || 0;
    this.navigationHeight = options.navigationHeight || 0;

    this.setupListeners();
  }

  setupListeners() {
    this.anchors.forEach((anchor) => {
      anchor.addEventListener('click', (e) => {
        e.preventDefault();

        const targetId = anchor.getAttribute('href').substring(1);
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
          const headerHeight = parseFloat(
            document.documentElement.style.getPropertyValue('--header-height'),
          );
          const targetOffset =
            this.getTotalOffsetTop(targetElement) - headerHeight - 20;

          window.scrollTo({
            top: targetOffset,
            behavior: 'smooth',
          });

          this.anchors.forEach((a) => {
            a.parentNode.classList.remove('active');
          });

          // anchor.parentNode.classList.add("active");
        }
      });
    });

    window.addEventListener('scroll', () => {
      const fromTop = window.scrollY + this.headerHeight;

      this.sections.forEach((section) => {
        if (
          section.offsetTop <= fromTop &&
          section.offsetTop + section.offsetHeight > fromTop
        ) {
          this.anchors.forEach((anchor) => {
            anchor.parentNode.classList.remove('active');
          });

          const targetAnchor = document.querySelector(
            `a[href="#${section.id}"]`,
          );

          if (targetAnchor) {
            targetAnchor.parentNode.classList.add('active');
          }
        }
      });
    });
  }

  getTotalOffsetTop(element) {
    var totalOffsetTop = 0;

    while (element) {
      totalOffsetTop += element.offsetTop;
      element = element.offsetParent;
    }

    return totalOffsetTop;
  }
}
export default ScrollSmooth;
