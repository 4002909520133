class RangeSelect {
  constructor(selector = '.RangeSelect') {
    this.elements = document.querySelectorAll(selector);

    if (this.elements.length === 0) {
      return;
    }
    this.elements.forEach((element) =>
      element.RangeSelect ? null : this.setupElement(element),
    );

    // console.warn(`No elements found for selector: ${selector}`);
  }

  setupElement(element) {
    const fromSelect = element.querySelector('select[name$="From"]');
    const toSelect = element.querySelector('select[name$="Up"]');

    if (!fromSelect || !toSelect) {
      console.warn("Missing 'From' or 'Up' select elements.");
      return;
    }

    fromSelect?.addEventListener('change', () => {
      this.updateSelectOptions(fromSelect, toSelect);
    });

    toSelect?.addEventListener('change', () => {
      this.updateSelectOptions(toSelect, fromSelect);
    });

    element.RangeSelect = true;
  }

  updateSelectOptions(selectedSelect, targetSelect) {
    const selectedPrice = parseInt(
      selectedSelect.querySelector('option:checked').dataset.rangeValue,
    );
    for (const option of targetSelect.options) {
      const price = parseInt(option.dataset.rangeValue);
      if (selectedSelect.name.endsWith('From') && price <= selectedPrice) {
        option.disabled = true;
      } else if (selectedSelect.name.endsWith('Up') && price >= selectedPrice) {
        option.disabled = true;
      } else {
        option.disabled = false;
      }
    }
    targetSelect.tomselect?.clearOptions();
    targetSelect.tomselect?.sync();
  }
}

export default RangeSelect;
