import { isIOS_Safari } from './functions.js';

class Burger {
  constructor() {
    this.btnToggleNav = document.getElementById('burgerButton');
    this.navigationBlock = document.getElementById('burgerNavigation');
    this.overlayBlock = document.getElementById('burgerOverlay');
    this.isOpen = false;
    this.menu = this.navigationBlock.querySelector('ul');
    this.isIOS_Safari = isIOS_Safari();
    this.setupListeners();
  }

  setupListeners() {
    this.btnToggleNav?.addEventListener('click', this.toggleBurger.bind(this));
    this.overlayBlock?.addEventListener('click', this.toggleBurger.bind(this));
    // this.navigationBlock?.querySelectorAll("li").forEach((el) => {
    //     el.addEventListener("click", this.toggleBurger.bind(this));
    // });
  }

  toggleBurger() {
    if (this.isOpen) {
      this.closeBurger();
    } else {
      this.openBurger();
    }
  }

  closeBurger() {
    if (this.isOpen == false) {
      return;
    }

    document.getElementById('body').classList.remove('overflow-hidden');
    document.getElementById('body').classList.remove('burger-opened');
    this.btnToggleNav.querySelectorAll('span').forEach((node) => {
      node.classList.toggle('opacity-0');
      node.classList.toggle('-rotate-90');
    });
    this.navigationBlock.classList.add('hidden');
    this.overlayBlock.classList.add('hidden');
    this.isOpen = false;

    if (this.isIOS_Safari) {
      // Видалити обробники подій для відновлення скроллу
      window.removeEventListener('scroll', this.preventScroll);
      document.removeEventListener('wheel', this.preventScroll);
      document.removeEventListener('touchmove', this.preventScroll);

      // Якщо меню може скролитися, то заборонити скролл на меню
      this.disAllowScrollOnMenu();
    }
  }

  openBurger() {
    if (this.isOpen) {
      return;
    }

    try {
      MicroModal.close();
    } catch {
      // pass
    }

    document.getElementById('body').classList.add('overflow-hidden');
    document.getElementById('body').classList.add('burger-opened');
    this.btnToggleNav.querySelectorAll('span').forEach((node) => {
      node.classList.toggle('opacity-0');
      node.classList.toggle('-rotate-90');
    });
    this.navigationBlock.classList.remove('hidden');
    this.overlayBlock.classList.remove('hidden');
    this.isOpen = true;

    // Додати обробники подій для блокування скроллу
    if (this.isIOS_Safari) {
      window.addEventListener('scroll', this.preventScroll, { passive: false });
      document.addEventListener('wheel', this.preventScroll, {
        passive: false,
      });
      document.addEventListener('touchmove', this.preventScroll, {
        passive: false,
      });
    }

    // Якщо меню може скролитися, то дозволити скролл на меню
    if (this.isIOS_Safari && this.canScroll(this.menu)) {
      this.allowScrollOnMenu();
    }
  }

  // Функція для блокування скроллу
  preventScroll(e) {
    e.preventDefault();
    e.stopPropagation();
    return false;
  }

  // Дозволити скролл на меню
  allowScrollOnMenu() {
    this.menu.addEventListener(
      'scroll',
      (e) => {
        e.stopPropagation();
      },
      { passive: true },
    );

    this.menu.addEventListener(
      'wheel',
      (e) => {
        e.stopPropagation();
      },
      { passive: true },
    );

    this.menu.addEventListener(
      'touchmove',
      (e) => {
        e.stopPropagation();
      },
      { passive: true },
    );
  }

  disAllowScrollOnMenu() {
    this.menu.removeEventListener(
      'scroll',
      (e) => {
        e.stopPropagation();
      },
      { passive: true },
    );

    this.menu.removeEventListener(
      'wheel',
      (e) => {
        e.stopPropagation();
      },
      { passive: true },
    );

    this.menu.removeEventListener(
      'touchmove',
      (e) => {
        e.stopPropagation();
      },
      { passive: true },
    );
  }

  canScroll(element) {
    return element.scrollHeight > element.clientHeight;
  }
}

export default Burger;
