export default function getOperatorCode(value) {
  const operatorCode = value.replace(/\D/g, '').substr(2, 3);
  let operator = false;

  if (theme.i18n.locale === 'uk-UA') {
    const operatorsUA = {
      Vodafone: ['050', '066', '095', '099'],
      Київстар: ['067', '068', '096', '097', '098'],
      Lifecell: ['063', '073', '093'],
      '3Mob': ['091'],
      PEOPLEnet: ['092'],
      Інтертелеком: ['094'],
    };

    for (const [name, codes] of Object.entries(operatorsUA)) {
      if (codes.includes(operatorCode)) {
        return name;
      }
    }
    return false;
  } else if (theme.i18n.locale === 'kk-KZ') {
    const regexPatterns = [
      /^\+7(700|701|702|705|707|708|747|750|751|760|761|762|771|775|776|777|778)\d{7}$/,
      /^8(700|701|702|705|707|708|747|750|751|760|761|762|771|775|776|777|778)\d{7}$/,
    ];

    // Удаляем все символы, кроме цифр и плюса (для международного формата)
    const sanitizedValue = value.replace(/[^+\d]/g, '');
    const normalizedValue = sanitizedValue.startsWith('7')
      ? `+${sanitizedValue}`
      : sanitizedValue;

    // Проверяем соответствие казахстанскому шаблону
    for (const pattern of regexPatterns) {
      if (pattern.test(normalizedValue)) {
        return true;
      }
    }
    return false;
  } else if (theme.i18n.locale === 'pl-PL') {
    return true;
  }
  return operator;
}
