class ScrollHeader {
  constructor(id) {
    this.button = document.getElementById(id);

    if (this.button) {
      this.lastScrollPosition = 0;
      this.update = this.update.bind(this);
      this.init();
    }
  }

  init() {
    this.buttonOffsetTop = document.getElementById('content')
      ? document.getElementById('content').offsetTop
      : 0;
    this.update();
  }

  destroy() {
    cancelAnimationFrame(this.animationFrameId);
  }

  update() {
    const currentScrollPosition =
      window.scrollY || document.documentElement.scrollTop;
    if (
      currentScrollPosition >
      this.buttonOffsetTop - document.getElementById('header').clientHeight
    ) {
      this.button.classList.add('active');
    } else {
      this.button.classList.remove('active');
    }

    this.lastScrollPosition = currentScrollPosition;
    this.animationFrameId = requestAnimationFrame(this.update);
  }
}

export default ScrollHeader;
