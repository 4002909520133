class Tabs {
  constructor() {
    this.auctionItems = document.querySelectorAll('[data-tabs-content]');
    this.tabButtons = document.querySelectorAll('[data-tabs-btn]');
    this.auctionTitleElement = document.getElementById('auction-title');
    this.auctionInputElement = document.getElementById('auctionInput');
    if (this.auctionItems.length > 0 && this.tabButtons.length > 0) {
      this.init();
    }
  }

  init() {
    this.tabButtons.forEach((item, index) => {
      item.addEventListener('click', () => {
        this.toggleTab(index);
        if (this.auctionTitleElement && this.auctionInputElement) {
          this.updateAuctionInfo(item);
        }
      });
    });

    // Встановлення активної вкладки за замовчуванням
    const defaultTab = 0;
    this.toggleTab(defaultTab);
    if (this.auctionTitleElement && this.auctionInputElement) {
      this.updateAuctionInfo(this.tabButtons[defaultTab]);
    }
  }

  toggleTab(index) {
    this.tabButtons.forEach((el) => el.classList.remove('active'));
    this.auctionItems.forEach((el) => el.classList.remove('active'));
    this.tabButtons[index].classList.add('active');
    this.auctionItems[index].classList.add('active');
  }

  updateAuctionInfo(selectedTab) {
    this.auctionTitleElement.innerText = selectedTab.dataset.title;
    this.auctionInputElement.value = selectedTab.dataset.title;
  }
}
export default Tabs;
